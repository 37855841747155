import classNames from 'classnames';
import { MasterCardCta } from 'components';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import {
	AttentionCard,
	Container,
	DescriptionList,
	DescriptionListProps,
	Form,
	FormfieldCheckbox,
	FormfieldGroup,
	FormfieldRadio,
	FormfieldSelect,
	FormfieldString,
	Grid,
	GridCell,
	Label,
	Usabilla,
} from 'shared';
import { getInputDateLimit, getSixMonthBackDate, getTomorrowDate } from 'utilities';
import { DictionaryContext } from '../../../../context/Dictionary';
import { _Actions, _Errors, _MasterCard, _Policy, _Units } from '../../../../dictionaryItems';
import { getPartnerByCardType } from '../../../../utilities/getPartnerByCadType';
import { MasterCardContent } from './MasterCardContent';
import styles from './MasterCardTravel.module.scss';
import { UsabillaWidgetIdFlow } from '../../../../utilities/loadUsabillaScript';
import { PageContext } from '../../../../context/PageContext';
import { getCmsFieldLabel, getCmsFieldHelpText, getCmsFieldPlaceholder } from '../../../../services/dictionaryService';

export interface MasterCardTravelProps extends PageModel {
	callbackContinue: (data: MasterCardTravelFormData) => void;
	callbackPrevious: () => void;
	callbackTravelForm: (data: MasterCardTravelFormData) => void;
	className?: string;
	errorIcon: Image;
	governmentInsurance: string;
	masterCardAttentionIcon?: BannerImage;
	maxMonthsCovered: number;
	maxNumberOfInsured: number;
	cardType: MasterCardType;
	cardTypeGroup: MasterCardTypeGroup;
	hasExtendedPeriodOption: boolean;
	priceDetailsList: DescriptionListProps;
	travelFormData: MasterCardTravelFormData;
	status: Status;
}

export interface MasterCardTravelFormData {
	insuredCount?: string;
	isExtension?: boolean;
	startDate?: string;
	endDate?: string;
	price?: string;
	priceTravelCompensation?: string;
	extendedTravelCoverageSelected?: boolean;
	skiCoverageSelected?: boolean;
	priceExtendedCancellationSelected?: boolean;
	priceTravelCompensationSelected?: boolean;
	skiCoverageRegion?: 'europe' | 'world';
}

type Option = {
	text: string;
	value: string;
	selected?: boolean;
};

export const MasterCardTravel: React.FC<MasterCardTravelProps> = ({
	callbackContinue,
	callbackPrevious,
	callbackTravelForm,
	className,
	errorIcon,
	governmentInsurance,
	masterCardAttentionIcon,
	maxMonthsCovered,
	maxNumberOfInsured,
	cardType,
	cardTypeGroup,
	hasExtendedPeriodOption,
	priceDetailsList,
	travelFormData,
	status,
	travelPeriodTitle,
	travelPeriodSubtitle,
	travelPeriodText,
	travelPeriodHelpText,
	skiTravelTitle,
	showSkiArea,
	coveragesTitle,
	cancellationTitle,
	compensationTitle,
	travelInfoFields,
	theme,
}) => {
	const { overviewFields } = useContext(PageContext);

	const dictionary = useContext(DictionaryContext);

	const [formData, setFormData] = useState<MasterCardTravelFormData>(travelFormData);

	const { control, handleSubmit, register, watch, setValue, getValues, trigger } = useForm<MasterCardTravelFormData>({
		mode: 'onChange',
		defaultValues: { insuredCount: '1', skiCoverageRegion: formData?.skiCoverageRegion || 'europe' },
	});

	const [errorSummary, setErrorSummary] = useState(0);

	const [isSydbank, setIsSydbank] = useState(false);

	const [isExtendedCoverage, setIsExtendedCoverage] = useState(
		travelFormData?.extendedTravelCoverageSelected != null ? travelFormData?.extendedTravelCoverageSelected : false,
	);

	const [insuredCountOptions, setInsuredCountOptions] = useState<Option[]>([]);

	const [isSkiCoverageSelected, setIsSkiCoverageSelected] = useState(formData?.skiCoverageSelected);

	const { errors, isValid } = useFormState({
		control,
	});

	const partner = getPartnerByCardType(cardType);

	useEffect(() => {
		const startDate = new Date(formData?.startDate);
		const endDate = new Date(formData?.endDate);

		const timeDifference = endDate.getTime() - startDate.getTime();
		const dayDifference = timeDifference / (1000 * 3600 * 24);

		if (dayDifference > 60) setIsExtendedCoverage(true);
		if (dayDifference <= 60 && formData.isExtension) {
			setIsExtendedCoverage(true);
		}
		if (dayDifference <= 60 && !formData.isExtension) {
			setIsExtendedCoverage(false);
		}
	}, [formData]);

	useEffect(() => {
		const options = [];
		for (let i = 1; i <= maxNumberOfInsured; i++) {
			if (i == 1) options.push({ text: `${i} ${_Units(dictionary, 'Person')}`, value: `${i}` });
			else
				options.push({
					text: `${i} ${_Units(dictionary, 'Persons')}`,
					value: `${i}`,
				});
		}
		setInsuredCountOptions(options);
	}, [dictionary, maxNumberOfInsured]);

	const onSubmit: SubmitHandler<MasterCardTravelFormData> = (data) => {
		if (getValues().priceTravelCompensationSelected) data.priceTravelCompensation = data?.price;
		if (!getValues().priceExtendedCancellationSelected) delete data?.price;

		callbackContinue(data);
	};

	useEffect(() => {
		const subscription = watch((value) => {
			const savedPrice = value.price;

			if (!value.priceExtendedCancellationSelected && isSydbank) delete value.price;
			if (value.priceTravelCompensationSelected && isSydbank) value.priceTravelCompensation = savedPrice;

			setFormData(value);

			if (isValid) callbackTravelForm(value as MasterCardTravelFormData);
		});

		return () => subscription.unsubscribe();
	}, [callbackTravelForm, isValid, watch]);

	const onError = (data) => {
		const totalErrors = Object.keys(data).length;
		setErrorSummary(totalErrors);
	};

	useEffect(() => {
		setIsSydbank(theme === 'Sydbank-theme' || theme === 'Dark-theme Sydbank-theme');
		// on load try calculate
		setTimeout(() => {
			tryCalculate();
		}, 500);
	}, []);

	const tryCalculate = () => {
		if (Object.getOwnPropertyNames(errors).length !== 0) {
			// form has errors
			console.warn('errors', errors);
			return;
		}
		const values = getValues();
		if (values.insuredCount && values.startDate && values.endDate) {
			if (values.insuredCount !== travelFormData.insuredCount) {
				if (maxNumberOfInsured < parseInt(travelFormData.insuredCount)) {
					// user has changed card type, and insuredCount is too high -> reset
					setValue('insuredCount', travelFormData.insuredCount);
					setValue('startDate', undefined);
					setValue('endDate', undefined);
					setValue('skiCoverageSelected', undefined);
					setValue('skiCoverageRegion', undefined);
					return;
				} else {
					setValue('insuredCount', travelFormData.insuredCount);
				}
			}
			if (!values?.price) {
				if (travelFormData.price) {
					setValue('price', travelFormData.price);
				}
			}
			trigger([
				'insuredCount',
				'startDate',
				'endDate',
				'extendedTravelCoverageSelected',
				'priceTravelCompensationSelected',
				'priceExtendedCancellationSelected',
				'price',
			]);
		}
	};

	const continueDisabled = () => {
		const notValid =
			!isValid ||
			priceDetailsList?.descriptionList?.[priceDetailsList?.descriptionList?.length - 1]?.description === '0' ||
			!priceDetailsList;

		// if (!notValid) {
		// 	const hasPriceSelection =
		// 		travelFormData.priceExtendedCancellationSelected ||
		// 		travelFormData.priceTravelCompensationSelected ||
		// 		travelFormData.extendedTravelCoverageSelected;
		// 	if (hasPriceSelection) {
		// 		const priceFilled = travelFormData?.price?.length > 0;
		// 		return !priceFilled;
		// }
		// }

		return notValid;
	};

	return (
		<div className={classNames(styles.MasterCardTravel, className)}>
			<Form onSubmit={handleSubmit(onSubmit, onError)} errorSummary={errorSummary}>
				<Container width={theme !== 'Dark-theme Sydbank-theme' ? 'Medium' : null}>
					<FormfieldGroup
						id="insuredCountGroup"
						className="u-top-margin--none"
						name="insuredCountGroup"
						label={_MasterCard(dictionary, 'TheTravelers')}
						style="blocked"
					>
						<FormfieldSelect
							className={styles.MasterCardTravel_countField}
							id="insuredCount"
							name="insuredCount"
							label={_Policy(dictionary, 'NumberOfInsured', overviewFields)}
							defaultValue={travelFormData?.insuredCount ? travelFormData.insuredCount : '1'}
							helpCtaText={
								getCmsFieldHelpText(travelInfoFields, 'insuredCount') ||
								_MasterCard(dictionary, 'NumberOfInsuredHelpText')
							}
							closeCtaText={_Actions(dictionary, 'Close')}
							options={insuredCountOptions}
							register={register}
							state={errors.insuredCount ? { hasError: true, required: true } : { required: true }}
						/>
					</FormfieldGroup>

					<FormfieldGroup
						id="travelPeriodGroup"
						name="travelPeriodGroup"
						label={travelPeriodTitle || _MasterCard(dictionary, 'TravelPeriod')}
						style="blocked"
						helpCtaText={isSydbank && travelPeriodHelpText}
						closeCtaText={isSydbank && _Actions(dictionary, 'Close')}
					>
						{isSydbank && (
							<MasterCardContent
								heading={travelPeriodSubtitle || 'Udvid din rejseperiode med ekstra dage'}
								additionalText={travelPeriodText || _MasterCard(dictionary, 'TravelPeriodSubtitleLopi')}
							/>
						)}

						{!isSydbank && cardTypeGroup != 'standard' && (
							<MasterCardContent
								heading={travelPeriodSubtitle || _MasterCard(dictionary, 'ExtendedTravelInsurance')}
							/>
						)}
						<MasterCardContent
							text={_MasterCard(dictionary, `TravelPeriodSubtitle${partner}`)}
							additionalText={
								cardTypeGroup === 'worldelite'
									? _MasterCard(dictionary, `TravelPeriodSubtitleWorldElite${partner}`)
									: cardTypeGroup != 'standard' &&
									  _MasterCard(dictionary, 'TravelPeriodSubtitlePremium')
							}
						/>

						{cardTypeGroup != 'standard' && (
							<FormfieldCheckbox
								id="extendedTravelCoverageSelected"
								name="extendedTravelCoverageSelected"
								label={
									getCmsFieldLabel(travelInfoFields, 'extendedTravelCoverageSelected') ||
									_MasterCard(dictionary, 'IWantToExtendedTravelInsurance')
								}
								defaultChecked={true}
								register={register}
								callback={(checked) => {
									if (!isSydbank && !checked) {
										setValue('isExtension', checked);
									}
								}}
							/>
						)}

						<FormfieldString
							id="startDate"
							name="startDate"
							className={classNames(styles.MasterCardTravel_dateField, {
								[styles.hasValue]: formData?.startDate?.length,
							})}
							label={_MasterCard(dictionary, 'DepartureDate')}
							defaultValue={travelFormData?.startDate}
							type="date"
							register={register}
							min={getSixMonthBackDate()}
							state={errors.startDate ? { hasError: true, required: true } : { required: true }}
						/>

						<FormfieldString
							id="endDate"
							name="endDate"
							className={classNames(styles.MasterCardTravel_dateField, {
								[styles.hasValue]: formData?.endDate?.length,
							})}
							label={_MasterCard(dictionary, 'ReturnDate')}
							defaultValue={travelFormData?.endDate}
							type="date"
							min={getTomorrowDate()}
							max={formData?.startDate && getInputDateLimit(formData.startDate, maxMonthsCovered)}
							register={register}
							state={errors.endDate ? { hasError: true, required: true } : { required: true }}
						/>

						{isSydbank && showSkiArea && (
							<>
								<Label className={styles.MasterCardTravel_label} id="skiCoverageSelected">
									{skiTravelTitle || 'Skisport'}
								</Label>
								<FormfieldCheckbox
									className={styles.MasterCardTravel_checkbox}
									id="skiCoverageSelected"
									name="skiCoverageSelected"
									label={
										getCmsFieldLabel(travelInfoFields, 'skiCoverageSelected') ||
										'Ja tak, udvidelsen skal dække skisport i Europa'
									}
									defaultChecked={travelFormData?.skiCoverageSelected}
									register={register}
									callback={(checked) => {
										setIsSkiCoverageSelected(!isSkiCoverageSelected);
									}}
									helpCtaCloseText={_Actions(dictionary, 'Close')}
									helpCtaText={getCmsFieldHelpText(travelInfoFields, 'skiCoverageSelected')}
								/>
								{isSydbank && isSkiCoverageSelected && cardType !== 'MSGS' && (
									<>
										<Label className={styles.MasterCardTravel_label} id="skiCoverageEurope">
											Jeg skal på skiferie i:
										</Label>
										<Grid className={classNames(styles.MasterCardTravel_grid)} wrap>
											<GridCell desktopWidth="100">
												<FormfieldRadio
													style="default"
													name="skiCoverageRegion"
													value="europe"
													label="Europa"
													id="skiCoverageEurope"
													register={register}
													defaultChecked={
														travelFormData?.skiCoverageRegion === 'europe' && true
													}
												/>
											</GridCell>
											<GridCell desktopWidth="100">
												<FormfieldRadio
													style="default"
													name="skiCoverageRegion"
													value="world"
													label="Verden (uden for Europa)"
													id="skiCoverageWorld"
													register={register}
													defaultChecked={
														travelFormData?.skiCoverageRegion === 'world' && true
													}
												/>
											</GridCell>
										</Grid>
									</>
								)}
							</>
						)}
						{!isSydbank && hasExtendedPeriodOption && (
							<>
								<FormfieldCheckbox
									className={styles.MasterCardTravel_checkbox}
									id="isExtension"
									name="isExtension"
									label={_MasterCard(dictionary, 'ExtensionOfAlreadyPurchasedAdditionalPeriod')}
									defaultChecked={travelFormData?.isExtension}
									register={register}
									callback={(checked) => {
										setValue('extendedTravelCoverageSelected', checked);
										tryCalculate();
									}}
									helpCtaCloseText={_Actions(dictionary, 'Close')}
									helpCtaText={_MasterCard(
										dictionary,
										'ExtensionOfAlreadyPurchasedAdditionalPeriodHelpText',
									)}
								/>

								<MasterCardContent
									text={_MasterCard(
										dictionary,
										'ExtensionOfAlreadyPurchasedAdditionalPeriodDescription',
									)}
								/>
							</>
						)}
					</FormfieldGroup>

					{isSydbank ? (
						<FormfieldGroup
							id="PriceGroup"
							name="PriceGroup"
							label={_MasterCard(dictionary, 'Coverages')}
							style="blocked"
						>
							<>
								<Label
									className={styles.MasterCardTravel_label}
									id="priceExtendedCancellationSelectedTitle"
								>
									{getCmsFieldLabel(travelInfoFields, 'priceExtendedCancellationSelectedTitle') ||
										'Udvidet afbestillingsforsikring'}
								</Label>
								<FormfieldCheckbox
									className={styles.MasterCardTravel_checkbox}
									id="priceExtendedCancellationSelected"
									name="priceExtendedCancellationSelected"
									label={
										getCmsFieldLabel(travelInfoFields, 'priceExtendedCancellationSelected') ||
										'Ja tak, jeg ønsker at udvide summen på min afbestillingsforsikring'
									}
									defaultChecked={travelFormData?.priceExtendedCancellationSelected}
									register={register}
									callback={(checked) => {
										setValue('priceExtendedCancellationSelected', checked);
										tryCalculate();
									}}
									helpCtaText={getCmsFieldHelpText(
										travelInfoFields,
										'priceExtendedCancellationSelected',
									)}
									helpCtaCloseText={_Actions(dictionary, 'Close')}
								/>
							</>

							<>
								<Label
									className={styles.MasterCardTravel_label}
									id="priceTravelCompensationSelectedTitle"
								>
									{getCmsFieldLabel(travelInfoFields, 'priceTravelCompensationSelectedTitle') ||
										'Udvidet kompensation for ødelagte feriedage/erstatningsrejse'}
								</Label>
								<FormfieldCheckbox
									className={styles.MasterCardTravel_checkbox}
									id="priceTravelCompensationSelected"
									name="priceTravelCompensationSelected"
									label={
										getCmsFieldLabel(travelInfoFields, 'priceTravelCompensationSelected') ||
										'Ja tak, jeg ønsker at udvide summen på min kompensation for ødelagte feriedage/erstatningsrejse'
									}
									defaultChecked={travelFormData?.priceTravelCompensationSelected}
									register={register}
									callback={(checked) => {
										setValue('priceTravelCompensationSelected', checked);
										tryCalculate();
									}}
									helpCtaText={getCmsFieldHelpText(
										travelInfoFields,
										'priceTravelCompensationSelected',
									)}
									helpCtaCloseText={_Actions(dictionary, 'Close')}
								/>
							</>

							<FormfieldString
								id="price"
								name="price"
								label={
									getCmsFieldLabel(travelInfoFields, 'price') ||
									_MasterCard(dictionary, 'ThePriceOfTheTrip')
								}
								defaultValue={travelFormData?.price}
								//value={travelFormData?.price}
								type="number"
								register={register}
								helpCtaText={
									getCmsFieldHelpText(travelInfoFields, 'price') ||
									_MasterCard(dictionary, 'ThePriceOfTheTripHelpText')
								}
								closeCtaText={_Actions(dictionary, 'Close')}
								className={styles.MasterCardTravel_priceField}
								min={25}
								placeholder={getCmsFieldPlaceholder(travelInfoFields, 'price') || 'F.eks 75.000 kr.'}
								max={Infinity}
								state={
									getValues().priceExtendedCancellationSelected ||
									getValues().priceTravelCompensationSelected
										? { required: true, disabled: false }
										: { disabled: true }
								}
							/>
						</FormfieldGroup>
					) : (
						<FormfieldGroup
							id="PriceGroup"
							name="PriceGroup"
							label={coveragesTitle || _MasterCard(dictionary, 'Coverages')}
							style="blocked"
						>
							{cardType === 'MPN' || cardType === 'WEMN' ? (
								<>
									<MasterCardContent
										heading={
											cancellationTitle ||
											_MasterCard(dictionary, 'ExtendedCancellationFeeBrokenHoliday')
										}
										text={
											cardType === 'MPN'
												? _MasterCard(
														dictionary,
														'ExtendedCancellationFeeBrokenHolidaySubtitlePlatinum',
												  )
												: cardType === 'WEMN'
												? _MasterCard(
														dictionary,
														'ExtendedCancellationFeeBrokenHolidaySubtitleWorldElite',
												  )
												: null
										}
									/>
									<FormfieldString
										id="priceTravelCompensation"
										name="priceTravelCompensation"
										label={_MasterCard(dictionary, 'ThePriceOfTheTrip')}
										defaultValue={travelFormData?.priceTravelCompensation}
										type="number"
										register={register}
										helpCtaText={_MasterCard(dictionary, 'ThePriceOfTheTripHelpText')}
										closeCtaText={_Actions(dictionary, 'Close')}
										className={styles.MasterCardTravel_priceField}
										min={25}
										max={cardType === 'MPN' ? 575000 : Infinity}
									/>
								</>
							) : null}

							<MasterCardContent
								heading={compensationTitle || _MasterCard(dictionary, 'ExtendedCancellationFee')}
								text={
									cardTypeGroup === 'worldelite'
										? _MasterCard(dictionary, `ExtendedCancellationFeeSubtitleWorldElite${partner}`)
										: cardTypeGroup === 'standard'
										? _MasterCard(dictionary, `ExtendedCancellationFeeSubtitle${partner}`)
										: cardTypeGroup === 'platinum'
										? _MasterCard(
												dictionary,
												`ExtendedCancellationFeeSubtitlePlatinum${
													partner === 'Nykredit' && cardType === 'MPN'
														? 'NykreditMPN'
														: partner === 'Nykredit' && cardType === 'MPBN'
														? 'NykreditMPBN'
														: 'Lopi'
												}`,
										  )
										: _MasterCard(dictionary, `ExtendedCancellationFeeSubtitlePremium${partner}`)
								}
							/>

							<FormfieldString
								id="price"
								name="price"
								label={_MasterCard(dictionary, 'ThePriceOfTheTrip')}
								defaultValue={travelFormData?.price}
								type="number"
								register={register}
								helpCtaText={_MasterCard(dictionary, 'ThePriceOfTheTripHelpText')}
								closeCtaText={_Actions(dictionary, 'Close')}
								className={styles.MasterCardTravel_priceField}
								min={25}
								max={
									cardType === 'MSBN'
										? 310000
										: cardTypeGroup === 'standard'
										? 50000
										: cardType === 'MGN'
										? 340000
										: cardType === 'MPN'
										? 575000
										: cardType === 'MPBN'
										? 560000
										: cardTypeGroup === 'platinum'
										? 300000
										: cardType === 'WEMN'
										? Infinity
										: 250000
								}
								state={
									errors.price
										? { hasError: true, required: cardTypeGroup === 'standard' }
										: { required: cardTypeGroup === 'standard' }
								}
								// TODO perhaps this needs to be implemented
								// state={
								// 	getValues().priceExtendedCancellationSelected ||
								// 	getValues().priceTravelCompensationSelected
								// 		? { required: true, disabled: false }
								// 		: { disabled: true }
								// }
							/>
						</FormfieldGroup>
					)}

					{isValid && priceDetailsList && status != 'error' && (
						<Container width="Medium">
							<DescriptionList
								className={styles.MasterCardTravel_descriptionList}
								horizontalLine={isSydbank}
								{...priceDetailsList}
							/>
							{isValid && priceDetailsList && (
								<AttentionCard
									conditionsText={
										`<p>` +
										`Lovgplitige afgifter udgør ` +
										` ${governmentInsurance} ` +
										_Units(dictionary, 'DanishCrowns') +
										`</p>`
									}
									image={masterCardAttentionIcon}
									cardType={{ type: 'disclaimer' }}
									cardSize={{ size: 'small' }}
									className={classNames(styles.MasterCardTravel_attentionCard)}
								/>
							)}
						</Container>
					)}
					{status === 'error' && (
						<Container width="Medium">
							<p className={styles.MasterCardTravel_errorLabel}>{_MasterCard(dictionary, 'YourPrice')}</p>
							<AttentionCard
								className={styles.MasterCardTravel_errorCard}
								image={errorIcon}
								conditionsText={`<p>` + _Errors(dictionary, 'FetchError') + `</p>`}
								cardType={{ type: 'error' }}
								cardSize={{ size: 'large' }}
							/>
						</Container>
					)}

					{/* {isValid && priceDetailsList && status != 'error' && (
						<AttentionCard
							conditionsText={
								`<p>` +
								_MasterCard(dictionary, 'PriceIncludesInsuranceToGovernment') +
								` ${governmentInsurance} ` +
								_Units(dictionary, 'DanishCrowns') +
								`</p>`
							}
							image={masterCardAttentionIcon}
							cardType={{ type: 'disclaimer' }}
							cardSize={{ size: 'small' }}
						/>
					)} */}

					<MasterCardCta
						className={styles.MasterCardTravel_cta}
						previous={true}
						callbackPrevious={callbackPrevious}
						continueText={_Actions(dictionary, 'Next')}
						submit
						continueDisabled={continueDisabled()}
					/>
				</Container>
			</Form>
			<Usabilla step="1" usabillaWidgetId={UsabillaWidgetIdFlow} category={'mastercard_sales_' + theme} />
		</div>
	);
};
